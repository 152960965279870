
































































import { t, tc } from "@/i18n";
import {
  AtomCard,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
  AtomAttentionBadge,
  AtomAttentionBadgeTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent } from "@vue/composition-api";
import dayjs from "dayjs";

const css = bemBuilder("billing-page-plan-summary");

export default defineComponent({
  name: "BillingPagePlanSummary",
  components: {
    AtomCard,
    AtomText,
    AtomLink,
    AtomAttentionBadge,
  },
  props: {
    planName: {
      type: String,
      required: true,
    },
    renewalDate: {
      type: String,
      default: null,
    },
    maxOutlets: {
      type: Number,
      default: 0,
    },
    subscriptionState: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedRenewalDate = computed(() => {
      const date = dayjs(props.renewalDate);

      return date.isValid() ? date : null;
    });

    const isSubscriptionCanceled = computed(
      () => props.subscriptionState === "CANCELED"
    );

    const displayStateBadge = computed(() =>
      ["PENDING_PAYMENT", "CANCELED"].includes(props.subscriptionState)
    );

    const stateBadgeType = computed(() =>
      props.subscriptionState === "PENDING_PAYMENT"
        ? AtomAttentionBadgeTypeEnum.WARN
        : AtomAttentionBadgeTypeEnum.DANGER
    );

    const stateBadgeLabel = computed(() =>
      props.subscriptionState === "PENDING_PAYMENT"
        ? t("c.billing.page.plan_summary.badge_pending_payment")
        : t("c.billing.page.plan_summary.badge_canceled")
    );

    return {
      css,
      t,
      tc,
      formattedRenewalDate,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
      AtomTextTagEnum,
      displayStateBadge,
      isSubscriptionCanceled,
      stateBadgeType,
      stateBadgeLabel,
    };
  },
});
