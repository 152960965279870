




























































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomCard,
  AtomTextColorEnum,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomTextTypeEnum,
  AtomGraphic,
  AtomButton,
} from "@/v2/new-design-system";
import { computed, defineComponent } from "@vue/composition-api";
import { IBillingPagePaymentInfoCardProps } from "./props";
import { t } from "@/i18n";
import { string } from "@/v2/util/prop/type";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import { useStripeCustomerPortal } from "../../use-stripe-customer-portal";
import dayjs from "dayjs";

const css = bemBuilder("billing-page-payment-info-card");

export default defineComponent<IBillingPagePaymentInfoCardProps>({
  name: "BillingPagePaymentInfoCard",
  components: {
    AtomText,
    AtomCard,
    AtomGraphic,
    AtomButton,
  },
  props: {
    businessName: string().isRequired().use(),
    businessLogo: string().isRequired().use(),
    businessId: string().isRequired().use(),
    subscriptionId: string().isRequired().use(),
    userEmail: string().isRequired().use(),
    userName: string().use(),
    cardLast4: string().use(),
    cardBrand: string().use(),
    cardExpireMonth: string().use(),
    cardExpireYear: string().use(),
  },
  setup(props) {
    const urlLogo = computed(() =>
      imageURLBuilder(props.businessLogo, 100, 100)
    );
    function cardBrandName(brandKey: string): string {
      if (brandKey === "UNIONPAY") {
        return "UnionPay";
      }
      if (brandKey === "AMERICAN_EXPRESS") {
        return "American Express";
      }
      if (brandKey === "DINERS_CLUB") {
        return "Diners Club";
      }

      const lowerCaseBrandKey = brandKey.toLowerCase();

      return (
        lowerCaseBrandKey.charAt(0).toUpperCase() + lowerCaseBrandKey.slice(1)
      );
    }

    const {
      openBillingCustomerPortal: manageBilling,
      isStripeCustomerPortalLoading: isManageBillingLoading,
    } = useStripeCustomerPortal();

    const {
      openBillingCustomerPortal: managePayment,
      isStripeCustomerPortalLoading: isManagePaymentLoading,
    } = useStripeCustomerPortal();

    const cardExpireDate = computed(() => {
      return dayjs()
        .month(parseInt(props.cardExpireMonth) - 1)
        .year(parseInt(props.cardExpireYear))
        .startOf("day");
    });

    const isCardExpired = computed(() => {
      const now = dayjs().startOf("day");

      return now.isAfter(cardExpireDate.value);
    });

    return {
      css,
      t,
      cardBrandName,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
      urlLogo,
      managePayment,
      isManagePaymentLoading,
      manageBilling,
      isManageBillingLoading,
      cardExpireDate,
      isCardExpired,
    };
  },
});
