

















































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  watch,
} from "@vue/composition-api";
import {
  MolGuideLink,
  MolGuideLinkArticleEnum,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { MolTypeformModal } from "@/v2/design-system";
import {
  fetchPaymentMethod,
  IFetchPaymentMethodResponse,
} from "@/v2/repo/fetch-payment-method";
import { billingPlanPriceFormatter } from "../../method/price-formatter";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "../../state";
import { BillingPagePlanSummary } from "../plan-summary";
import { BillingPagePaymentInfoCard } from "../payment-info-card";
import { BillingPageTrialInfoCard } from "../trial-info-card";
import { billingMethodTrack } from "../../method/track";
import { flow } from "@prosperstack/flow";
import { globalConfig } from "@/v2/core/global-config";
import { createProsperstackSignature } from "@/v2/repo/create-prosperstack-signature";
import { EventEnum } from "@/v2/enum";
import { t } from "@/i18n";

const css = bemBuilder("billing-page-main");

export default defineComponent({
  name: "BillingPageMain",
  components: {
    BillingPagePlanSummary,
    BillingPageTrialInfoCard,
    BillingPagePaymentInfoCard,
    MolGuideLink,
    MolTypeformModal,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const currentPaymentMethod = ref<IFetchPaymentMethodResponse | null>(null);

    const showTypeformModal = ref<boolean>(false);

    const hasActiveTrial = computed(
      () => billingGetState("trialDays").value > 0
    );

    const currentPlan = billingGetState("currentPlan");

    const hasFreePlan = computed(
      () => currentPlan.value?.plan.type === "SIGNUP"
    );

    const hasPaidPlan = computed(
      () => currentPlan.value?.plan.type === "REGULAR"
    );

    const hasLegacyPlan = computed(
      () => currentPlan.value?.plan.type === "LEGACY"
    );

    const hasCustomPlan = computed(
      () => currentPlan.value?.plan.type === "CUSTOM"
    );

    const business = billingGetState("business");

    watch(
      () => hasPaidPlan.value,
      async (value) => {
        if (value) {
          currentPaymentMethod.value = await fetchPaymentMethod(
            billingGetState("business").value.id
          );
        }
      },
      { immediate: true }
    );

    const formattedPlanPrice = computed(() =>
      billingPlanPriceFormatter(
        currentPlan.value?.plan.price,
        currentPlan.value?.plan.currency
      )
    );

    const vm = getCurrentInstance();

    const onClickSubscribeNowTrialCta = () => {
      // @ts-ignore
      billingMethodTrack(vm?.proxy.$analytics, EventEnum.CLICKED_ON_BUTTON, {
        label: "Subscribe now - billing page",
      });
      billingSetState("stage", BillingStageStateEnum.CHOOSE_PLAN);
    };

    async function churnFlow() {
      if (!currentPlan.value?.stripeSubscriptionId) {
        showTypeformModal.value = true;
        return;
      }

      const flowId = globalConfig.prosperstackFlowId;
      const paymentProviderId = currentPlan.value?.stripeSubscriptionId;

      const signature = await createProsperstackSignature({
        businessId: props.businessId,
        flowId,
        paymentProviderId,
      });

      const payload = JSON.stringify({
        clientId: globalConfig.prosperstackClientId,
        flowId,
        subscription: {
          paymentProviderId,
        },
      });

      await flow({ payload, signature });
    }

    return {
      css,
      t,
      churnFlow,
      hasActiveTrial,
      showTypeformModal,
      hasPaidPlan,
      hasCustomPlan,
      hasLegacyPlan,
      hasFreePlan,
      formattedPlanPrice,
      currentPaymentMethod,
      MolGuideLinkArticleEnum,
      onClickSubscribeNowTrialCta,
      user: billingGetState("user"),
      business,
      trialDays: billingGetState("trialDays"),
      currentPlan: billingGetState("currentPlan"),
    };
  },
});
