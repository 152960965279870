



















































































import { tc } from "@/i18n";
import { t } from "@/i18n";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomCard,
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { func, number } from "@/v2/util/prop/type";
import { computed, defineComponent } from "@vue/composition-api";
import { IBillingPageTrialInfoCardProps } from "./props";

const css = bemBuilder("billing-page-trial-info-card");

export default defineComponent<IBillingPageTrialInfoCardProps>({
  name: "BillingPageTrialInfoCard",
  components: {
    AtomText,
    AtomIcon,
    AtomCard,
    AtomButton,
  },
  props: {
    trialDays: number().isRequired().use(),
    onClickCTA: func().isRequired().use(),
  },
  setup(props) {
    const isTrialExpired = computed(() => {
      return props.trialDays <= 0;
    });

    const isTrialExpiringToday = computed(() => {
      return props.trialDays < 1 && props.trialDays > 0;
    });

    return {
      css,
      t,
      tc,
      isTrialExpired,
      isTrialExpiringToday,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
