





























import { t } from "@/i18n";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import {
  AtomButton,
  AtomLoading,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "@/v2/module/billing/state";

const css = bemBuilder("billing-page-draft");

export default defineComponent({
  name: "BillingPageDraft",
  components: { AtomLoading, AtomText, AtomButton, MolGuideLink },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);

    onMounted(() => {
      const currentPlan = billingGetState("currentPlan").value;

      if (currentPlan?.state !== "DRAFT") {
        location.replace(`/business/${props.businessId}`);
      }

      isLoading.value = false;
    });

    const showSubscription = () => {
      billingSetState("stage", BillingStageStateEnum.CHOOSE_PLAN);
    };

    return {
      t,
      css,
      isLoading,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
      showSubscription,
    };
  },
});
