import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";

interface ICreateProsperstackSignatureRequest {
  businessId: string;
  flowId: string;
  paymentProviderId: string;
}

export class CreateProsperstackSignatureError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreateProsperstackSignature");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createProsperstackSignature(
  request: ICreateProsperstackSignatureRequest
): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.post(
      `/prosperstack/businesses/${request.businessId}/sign-cancelation-payload`,
      {
        flow_id: request.flowId,
        payment_provider_id: request.paymentProviderId,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateProsperstackSignatureError(error);
  }

  return response.data.signature;
}
