var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('AtomCard',[_c('AtomText',{class:[
        _vm.css('heading'),
        ( _obj = {}, _obj[_vm.css('heading', 'expired')] = _vm.isTrialExpired, _obj ) ],attrs:{"type":_vm.AtomTextTypeEnum.H3}},[_vm._v(_vm._s(_vm.isTrialExpired ? _vm.t("c.billing.page.trial_info_card.expired") : _vm.isTrialExpiringToday ? _vm.t("c.billing.page.trial_info_card.expires_today") : _vm.tc( "c.billing.page.trial_info_card.will_expire", Math.floor(_vm.trialDays) )))]),_c('AtomText',{class:[
        _vm.css('subheading'),
        ( _obj$1 = {}, _obj$1[_vm.css('subheading', 'expired')] = _vm.isTrialExpired, _obj$1 ) ],attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(_vm._s(_vm.isTrialExpired ? _vm.t("c.billing.page.trial_info_card.subheading_1") : _vm.t("c.billing.page.trial_info_card.subheading_2")))]),_c('div',{class:_vm.css('points')},[_c('div',{class:_vm.css('points-col-1')},_vm._l(([
            ['pulse', _vm.t('c.billing.page.trial_info_card.info_1')],
            ['qr-code', _vm.t('c.billing.page.trial_info_card.info_2')],
            ['toggles', _vm.t('c.billing.page.trial_info_card.info_3')],
            ['feather', _vm.t('c.billing.page.trial_info_card.info_4')] ]),function(item,index){return _c('div',{key:index,class:_vm.css('point-item')},[_c('AtomIcon',{class:_vm.css('icon'),attrs:{"name":item[0],"color":"var(--color-chatfood)"}}),_c('AtomText',[_vm._v(" "+_vm._s(item[1])+" ")])],1)}),0),_c('div',{class:_vm.css('points-col-2')},_vm._l(([
            ['headphones', _vm.t('c.billing.page.trial_info_card.info_5')],
            ['credit-card', _vm.t('c.billing.page.trial_info_card.info_6')],
            ['gift', _vm.t('c.billing.page.trial_info_card.info_7')],
            ['heart', _vm.t('c.billing.page.trial_info_card.info_8')] ]),function(item,index){return _c('div',{key:index,class:_vm.css('point-item')},[_c('AtomIcon',{class:_vm.css('icon'),attrs:{"name":item[0],"color":"var(--color-chatfood)"}}),_c('AtomText',[_vm._v(" "+_vm._s(item[1])+" ")])],1)}),0)]),_c('AtomButton',{attrs:{"on-click":_vm.onClickCTA,"size":_vm.AtomButtonSizeEnum.SMALL}},[_vm._v(" "+_vm._s(_vm.t("c.billing.page.trial_info_card.CTA"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }